import React from "react";
import PropTypes from "prop-types";
import { slugify } from "~src/utils/strings";
import { MDXProvider } from "@mdx-js/react";
import styled, { ThemeProvider } from "styled-components";
import Header from "~components/ui/header";
import GlobalStyles from "~styles/GlobalStyles";
import theme from "~styles/theme";
import { useScrollTop } from "~hooks/use-scroll-top";
import { useScrollTo } from "~hooks/use-scroll-to";
import { useLocation } from "@gatsbyjs/reach-router";
import CombinedLogos from "../icons/combined-logos-united-blue";

const menuItems = [
  {
    name: "navlink-2024-resurgens",
    url: "/united#section-2024",
    display: "THE RESURGENS KIT",
  },
  {
    name: "navlink-2017",
    url: "/united#section-2017",
    display: "THE INAUGURAL SEASON",
  },
  {
    name: "navlink-2018",
    url: "/united#section-2018",
    display: "THE CHAMPIONSHIP SEASON",
  },
  {
    name: "navlink-2019",
    url: "/united#section-2019",
    display: "CUPS SEASON",
  },
  {
    name: "navlink-2020",
    url: "/united#section-2020",
    display: "THE KING’S SEASON",
  },
  {
    name: "navlink-2021",
    url: "/united#section-2021",
    display: "SEASON V",
  },
  {
    name: "navlink-unity",
    url: "/united#section-unity",
    display: "UNITY KIT",
  },
  {
    name: "navlink-2022",
    url: "/united#section-2022",
    display: "THE FOREST KIT",
  },
  {
    name: "navlink-our-mission",
    url: "/united#section-our-mission",
    display: "PROTECT THE ENVIRONMENT",
  },
  {
    name: "navlink-amfams-commitment",
    url: "/united#section-amfams-commitment",
    display: "WE DRIVE CHANGE TOGETHER",
  },
  {
    name: "navlink-2023-17s-kit",
    url: "/united#section-2023-17s-kit",
    display: "THE 17S KIT",
  },
  {
    name: "navlink-goalie-gloves",
    url: "/united#section-goalie-gloves",
    display: "GOALIE GLOVES",
  },
];

const UnitedLayout = ({ children }) => {
  const { pathname, hash } = useLocation();
  // force scrolling to top on load
  useScrollTop({ pathname, hash });
  // force scrolling to anchor on load after 1 sec
  useScrollTo({ hash });

  return (
    <ThemeProvider theme={theme("united")}>
      <MDXProvider>
        <GlobalStyles />

        <StyledLayout id='page-outer-wrapper'>
          <Header
            fixed={true}
            menuItems={menuItems}
            rootPath='/united'
            Logo={CombinedLogos}
          />
          {children}
        </StyledLayout>
      </MDXProvider>
    </ThemeProvider>
  );
};

const StyledLayout = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  /* overflow-x: hidden; */
  max-width: 100vw;
  /* box-sizing: content-box; */

  > header {
    display: block;
    max-width: 100vw;
  }
  > main {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    max-width: 100vw;
  }
`;

UnitedLayout.propTypes = {
  children: PropTypes.node,
};

export default UnitedLayout;
